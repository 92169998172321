define("front/templates/faq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yGFoJc+A",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[35,0]],[[\"_deprecate\"],[\"/tmp/broccoli-714ZcNrfZ5rMEpS/out-709-colocated_template_processor/front/templates/faq.hbs\"]]]],[2,\"\\n\\n\"],[10,\"div\"],[14,1,\"faq\"],[12],[2,\"\\n  \"],[8,\"header/main\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"header text-center\"],[12],[2,\"\\n        \"],[10,\"h1\"],[12],[1,[30,[36,2],[\"menu.support\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"faq-content\"],[12],[2,\"\\n    \"],[10,\"section\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[\"faq\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"main-footer\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"page-title\",\"t\"]}",
    "meta": {
      "moduleName": "front/templates/faq.hbs"
    }
  });

  _exports.default = _default;
});