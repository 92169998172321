define("front/templates/brands/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "onzvfA3E",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[35,0]],[[\"_deprecate\"],[\"/tmp/broccoli-714ZcNrfZ5rMEpS/out-709-colocated_template_processor/front/templates/brands/index.hbs\"]]]],[2,\"\\n\"],[8,\"header/main\",[],[[\"@stickyNav\",\"@fixed\"],[false,true]],null],[2,\"\\n\"],[8,\"brands-react/brands-react\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"page-title\"]}",
    "meta": {
      "moduleName": "front/templates/brands/index.hbs"
    }
  });

  _exports.default = _default;
});