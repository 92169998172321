define("front/templates/courses/course", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NxtfEPvC",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[1,[30,[36,1],[[35,0]],[[\"_deprecate\"],[\"/tmp/broccoli-714ZcNrfZ5rMEpS/out-709-colocated_template_processor/front/templates/courses/course.hbs\"]]]],[2,\"\\n\"],[8,\"header/main\",[],[[\"@stickyNav\"],[false]],null],[2,\"\\n\"],[8,\"course-react/course-react\",[],[[\"@id\"],[[32,1,[\"course_id\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"page-title\"]}",
    "meta": {
      "moduleName": "front/templates/courses/course.hbs"
    }
  });

  _exports.default = _default;
});